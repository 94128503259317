import React, { Suspense } from 'react';
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import { Loader } from '../components/Loader';

const AuthorizationPage = React.lazy(() => import('../pages/Authorization'));
const HomePage = React.lazy(() => import('../pages/Home'));

function MainRouter() {
    return (
        <Suspense fallback={<Loader />}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/authorization" element={<AuthorizationPage />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
}

export default MainRouter;