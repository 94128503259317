import { UserTypes, UserState, UserActions } from "./types";
import { Reducer } from "redux";

const defaultState: UserState = {
  tokenInfo: JSON.parse(`${localStorage.getItem("tokenInfo")}`),
  user: null,
  friends: [],
  currentFriend: null,
  position: undefined,
  histories: [],
  historiesByDate: [],
  address: null,
  loading: false,
  activeIndex: null,
};

export const userReducer: Reducer<UserState, UserActions> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case UserTypes.ACTIVE_INDEX: {
      return {
        ...state,
        activeIndex: action.activeIndex,
      };
    }
    case UserTypes.LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case UserTypes.ADDRESS: {
      return {
        ...state,
        address: action.address,
      };
    }
    case UserTypes.UPDATE_LOCATION: {
      return {
        ...state,
        [action.name]: action.payload,
        position: action.position,
        currentFriend: action.payload,
      };
    }
    case UserTypes.GET_HISTORY: {
      if (action.histories.length > 0) {
        return {
          ...state,
          histories: action.histories,
          historiesByDate: action.histories,
          position: undefined,
        };
      }
      return {
        ...state,
        histories: action.histories,
        historiesByDate: action.histories,
      };
    }
    case UserTypes.GET_HISTORY_BY_DATE: {
      return {
        ...state,
        historiesByDate: action.histories,
      };
    }
    case UserTypes.POSITION: {
      if (action.position) {
        return {
          ...state,
          position: action.position,
          histories: [],
        };
      }
      return {
        ...state,
        position: action.position,
      };
    }
    case UserTypes.CURRENT_FRIEND: {
      return {
        ...state,
        currentFriend: action.currentFriend,
        position: action.position,
        histories: [],
        historiesByDate: [],
      };
    }
    case UserTypes.GET_FRIENDS: {
      return {
        ...state,
        friends: action.friends,
      };
    }
    case UserTypes.GET_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    case UserTypes.GET_TOKEN_INFO: {
      localStorage.setItem("tokenInfo", JSON.stringify(action.tokenInfo));
      return {
        ...state,
        tokenInfo: action.tokenInfo,
      };
    }
    case UserTypes.LOGOUT: {
      localStorage.removeItem("tokenInfo");
      return {
        ...state,
        tokenInfo: null,
      };
    }
    default:
      return state;
  }
};
export default userReducer;
