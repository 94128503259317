import React from 'react';
import logoIcon from '../../img/logo.svg';
import { PropsFromRedux } from './HeaderContainer';

type Props = PropsFromRedux;

export const Header:React.FC<Props> = (props) => {
    const { logoutAction, tokenInfo } = props;
    return (
        <div className="container">
            <div className="header">
                <div className="header__logo">
                    <img src={logoIcon} alt="" />
                    <div className="header__text">iMapp Control Panel</div>
                </div>
                {tokenInfo && 
                    <button 
                        className="header__logout"
                        onClick={logoutAction}>
                        Log out
                    </button>
                }
            </div>
        </div>
    );
};