import { takeEvery, put, call, StrictEffect } from "redux-saga/effects";
import { Http, HTTPResponse } from "../../api";
import { Error, UserData, TokenInfo, User, Center, History } from "../../types";
import { activeIndexAction, loadingAction } from "./actions";
import {
  UserTypes,
  LoginAction,
  TokenAciton,
  UserAciton,
  FriendsAciton,
  PositionAciton,
  HistoriesAciton,
  HistoryAction,
  UpdateLocationByIdAction,
  UpdateLocationAciton,
} from "./types";

const http = new Http();

export function* userSaga(): Generator<StrictEffect> {
  yield takeEvery(UserTypes.REQUEST_LOGIN, loginWorker);
  yield takeEvery(UserTypes.REQUEST_USER, userWorker);
  yield takeEvery(UserTypes.REQUEST_FRIENDS, friendsWorker);
  yield takeEvery(UserTypes.REQUEST_HISTORY, historyWorker);
  yield takeEvery(UserTypes.REQUEST_UPDATE_LOCATION, updateLocationWorker);
  yield takeEvery(UserTypes.UPDATE_LOCATION, friendsWorker);
}

function* loginWorker(action: LoginAction) {
  try {
    const fetchTokenInfo = () =>
      http.post<UserData>("/User/login", action.payload);
    const { data }: HTTPResponse<TokenInfo> = yield call(fetchTokenInfo);
    yield put<TokenAciton>({
      type: UserTypes.GET_TOKEN_INFO,
      tokenInfo: data,
    });
  } catch (error: any) {
    const { data }: HTTPResponse<Error> = error;
    const textError = data.description || "Something went wrong";
    console.log("error:", error);
    alert(textError);
  }
}

function* userWorker() {
  try {
    const fetchUser = () => http.get<UserData>("/User/me");
    const { data }: HTTPResponse<User> = yield call(fetchUser);
    yield put<UserAciton>({
      type: UserTypes.GET_USER,
      user: data,
    });
    if (data && data.positionX && data.positionY) {
      const userPosition: Center = {
        lat: data.positionX,
        lng: data.positionY,
      };
      yield put<PositionAciton>({
        type: UserTypes.POSITION,
        position: userPosition,
      });
    }
  } catch (error: any) {
    const { data }: HTTPResponse<Error> = error;
    const textError = data.description || "Something went wrong";
    console.log("error:", error);
    alert(textError);
  }
}

function* friendsWorker() {
  try {
    const fetchFriends = () => http.get<UserData>("/User/friends");
    const { data }: HTTPResponse<User[]> = yield call(fetchFriends);
    yield put<FriendsAciton>({
      type: UserTypes.GET_FRIENDS,
      friends: data,
    });
  } catch (error: any) {
    const { data }: HTTPResponse<Error> = error;
    const textError = data.description || "Something went wrong";
    console.log("error:", error);
    alert(textError);
  }
}

function* historyWorker(action: HistoryAction) {
  try {
    yield put(activeIndexAction(null));
    yield put(loadingAction(true));
    const fetchHistories = () =>
      http.get<UserData>(`/User/history/${action.userId}`);
    const { data }: HTTPResponse<History[]> = yield call(fetchHistories);
    yield put<HistoriesAciton>({
      type: UserTypes.GET_HISTORY,
      histories: data,
    });
    yield put(loadingAction(false));
  } catch (error: any) {
    yield put(loadingAction(false));
    const { data }: HTTPResponse<Error> = error;
    const textError = data.description || "Something went wrong";
    console.log("error:", error);
    alert(textError);
  }
}

function* updateLocationWorker(action: UpdateLocationByIdAction) {
  try {
    yield put(loadingAction(true));
    const fetchHistories = () =>
      http.get<UserData>(`/User/updateLocation/${action.userId}`);
    const { data }: HTTPResponse<User> = yield call(fetchHistories);
    if (!data.hasOwnProperty("code") && data.positionX && data.positionY) {
      const position: Center = {
        lat: data.positionX,
        lng: data.positionY,
      };
      yield put<UpdateLocationAciton>({
        type: UserTypes.UPDATE_LOCATION,
        payload: data,
        position: position,
        name: action.name,
      });
    }
    yield put(loadingAction(false));
  } catch (error: any) {
    yield put(loadingAction(false));
    const { data }: HTTPResponse<Error> = error;
    const textError = data.description || "Something went wrong";
    console.log("error:", error);
    alert(textError);
  }
}
