import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";

const headers: AxiosRequestHeaders | undefined = {
    "Access-Control-Allow-Origin": "*",
	"Accept": "application/json",
	"Content-Type": "application/json; charset=utf-8"
};

enum StatusCode {
	Unauthorized = 401,
	Forbidden = 403,
	TooManyRequests = 429,
	InternalServerError = 500,
}

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
	try {
		const tokenInfo = localStorage.getItem("tokenInfo");

		if (tokenInfo != null) {
            const token = JSON.parse(tokenInfo).jwt;
			config.headers!.Authorization = `Bearer ${token}`;
		}
		return config;
	} catch (error: any) {
		throw new Error(error);
	}
};

export type HTTPResponse<T> = AxiosResponse<T>;

export class Http {

	private instance: AxiosInstance | null = null;
	private baseURL: string | undefined;

	constructor(baseURL?: string) {
		this.baseURL = baseURL;
	}

	private get http(): AxiosInstance {
		return this.instance != null ? this.instance : this.initHttp();
	}

	initHttp() {
		const http = axios.create({
			baseURL: this.baseURL || 'https://panel.imap-app.com',
			headers
		});

		http.interceptors.request.use(injectToken, (error) => Promise.reject(error));

		http.interceptors.response.use(
			(response) => response,
			(error) => {
				const { response } = error;
				return this.handleError(response);
			}
		);

		this.instance = http;
		return http;
	}

	request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
		return this.http.request(config);
	}

	get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this.http.get<T, R>(url, config);
	}

	post<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.post<T, R>(url, data, config);
	}

	put<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.put<T, R>(url, data, config);
	}

	delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this.http.delete<T, R>(url, config);
	}

	// Handle global app errors
	// We can handle generic app errors depending on the status code
	private handleError(error: any) {
		const { status } = error;
		
		switch (status) {
			case StatusCode.InternalServerError: {
				// Handle InternalServerError
				break;
			}
			case StatusCode.Forbidden: {
				// Handle Forbidden
				break;
			}
			case StatusCode.Unauthorized: {
				// Handle Unauthorized
				break;
			}
			case StatusCode.TooManyRequests: {
				// Handle TooManyRequests
				break;
			}
		}

		return Promise.reject(error);
	}
}
