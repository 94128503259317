import { ActionCreator } from 'redux';
import { Center, History, User, UserData } from '../../types';
import { 
    AddressAciton, CurrentFriendsAciton, HistoryAction, 
    LogoutAciton, PositionAciton, UpdateLocationByIdAction, UserTypes, HistoriesByDateAciton, LoadingAction, ActiveIndexAction
} from './types';

export const loginAction = (payload: UserData) => {
    return {
        type: UserTypes.REQUEST_LOGIN,
        payload
    }
}

export const logoutAction: ActionCreator<LogoutAciton> = () => {
    return {
        type: UserTypes.LOGOUT
    }
}

export const userAction = () => {
    return {
        type: UserTypes.REQUEST_USER
    }
}

export const friendsAction = () => {
    return {
        type: UserTypes.REQUEST_FRIENDS
    }
}

export const currentFriendAction: ActionCreator<CurrentFriendsAciton> = (currentFriend: User | null) => {
    let position: Center | undefined = undefined;
    if(currentFriend && currentFriend.positionX && currentFriend.positionY) {
        position = {
            lat: currentFriend.positionX,
            lng: currentFriend.positionY
        }
    }
    return {
        type: UserTypes.CURRENT_FRIEND,
        currentFriend,
        position: position
    }
}

export const positionAction: ActionCreator<PositionAciton> = (position?: Center) => {
    return {
        type: UserTypes.POSITION,
        position
    }
}

export const historyAction: ActionCreator<HistoryAction> = (userId: number) => {
    return {
        type: UserTypes.REQUEST_HISTORY,
        userId
    }
}

export const updateLocationAction: ActionCreator<UpdateLocationByIdAction> = (userId: number, name: string) => {
    return {
        type: UserTypes.REQUEST_UPDATE_LOCATION,
        userId,
        name
    }
}

export const addressAction: ActionCreator<AddressAciton> = (address: string | null) => {
    return {
        type: UserTypes.ADDRESS,
        address
    }
}

export const historiesByDateAction: ActionCreator<HistoriesByDateAciton> = (histories: History[]) => {

    return {
        type: UserTypes.GET_HISTORY_BY_DATE,
        histories
    }
}

export const loadingAction: ActionCreator<LoadingAction> = (loading: boolean) => {

    return {
        type: UserTypes.LOADING,
        loading
    }
}

export const activeIndexAction: ActionCreator<ActiveIndexAction> = (activeIndex: number | null) => {

    return {
        type: UserTypes.ACTIVE_INDEX,
        activeIndex
    }
}