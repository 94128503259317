import { Action } from "redux";
import { Center, TokenInfo, User, UserData, History } from "../../types";

export interface UserState {
    tokenInfo: TokenInfo | null;
    user: User | null;
    friends: User[];
    currentFriend: User | null;
    position?: Center;
    histories: History[];
    historiesByDate: History[];
    address: string | null;
    loading: boolean;
    activeIndex: number | null;
}

export enum UserTypes {
    REQUEST_LOGIN = 'REQUEST_LOGIN',
    GET_TOKEN_INFO = 'GET_TOKEN_INFO',
    LOGOUT = 'LOGOUT',
    REQUEST_USER = 'REQUEST_USER',
    GET_USER = 'GET_USER',
    REQUEST_FRIENDS = 'REQUEST_FRIENDS',
    GET_FRIENDS = 'GET_FRIENDS',
    CURRENT_FRIEND = 'CURRENT_FRIEND',
    POSITION = 'POSITION',
    REQUEST_HISTORY = 'REQUEST_HISTORY',
    GET_HISTORY = 'GET_HISTORY',
    REQUEST_UPDATE_LOCATION = 'REQUEST_UPDATE_LOCATION',
    UPDATE_LOCATION = 'UPDATE_LOCATION',
    ADDRESS = 'ADDRESS',
    GET_HISTORY_BY_DATE = 'GET_HISTORY_BY_DATE',
    LOADING = 'LOADING',
    ACTIVE_INDEX = 'ACTIVE_INDEX'
}

// Actions type
export interface LoginAction extends Action<UserTypes.REQUEST_LOGIN>{ payload: UserData }
export interface TokenAciton extends Action<UserTypes.GET_TOKEN_INFO>{ tokenInfo: TokenInfo | null }
export interface LogoutAciton extends Action<UserTypes.LOGOUT>{}
export interface UserAciton extends Action<UserTypes.GET_USER>{ user: User | null }
export interface FriendsAciton extends Action<UserTypes.GET_FRIENDS>{ friends: User[] }
export interface CurrentFriendsAciton extends Action<UserTypes.CURRENT_FRIEND>{ currentFriend: User | null, position?: Center }
export interface PositionAciton extends Action<UserTypes.POSITION>{ position?: Center }
export interface HistoryAction extends Action<UserTypes.REQUEST_HISTORY>{ userId: number }
export interface HistoriesAciton extends Action<UserTypes.GET_HISTORY>{ histories: History[] }
export interface UpdateLocationByIdAction extends Action<UserTypes.REQUEST_UPDATE_LOCATION>{ userId: number, name: string }
export interface UpdateLocationAciton extends Action<UserTypes.UPDATE_LOCATION>{ payload: User, name: string, position?: Center }
export interface AddressAciton extends Action<UserTypes.ADDRESS>{ address: string | null }
export interface HistoriesByDateAciton extends Action<UserTypes.GET_HISTORY_BY_DATE>{ histories: History[] }
export interface LoadingAction extends Action<UserTypes.LOADING>{ loading: boolean }
export interface ActiveIndexAction extends Action<UserTypes.ACTIVE_INDEX>{ activeIndex: number | null }

export type UserActions =
    | TokenAciton
    | LogoutAciton
    | UserAciton
    | FriendsAciton
    | CurrentFriendsAciton
    | PositionAciton
    | HistoriesAciton
    | UpdateLocationAciton
    | AddressAciton
    | HistoriesByDateAciton
    | LoadingAction
    | ActiveIndexAction;