import { ConnectedProps, connect } from 'react-redux';
import { Header } from './Header';
import { logoutAction } from '../../store/user/actions';
import './Header.scss';
import { RootState } from '../../store';

type Props = PropsFromRedux;

const HeaderContainer:React.FC<Props> = (props) => {
    return (
        <Header {...props} />
    );
};
  
const mapStateToProps = (state: RootState) => ({
    tokenInfo: state.user.tokenInfo
});

const mapDispatchToProps = {
    logoutAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HeaderContainer);