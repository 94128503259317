import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import MainRouter from './router';
import HeaderContainer from './components/Header';
import './index.scss';

const app = (
    <Provider store={store}>
        <HeaderContainer />
        <MainRouter />
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
